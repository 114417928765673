<template>
  <ExhibitorKit />
</template>

<script>
import ExhibitorKit from "@/components/exhibitorKit/ExhibitorKit";

export default {
  name: "ExhibitorKitPage",
  props: {},
  components: {
    ExhibitorKit,
  },
  data: () => ({}),
};
</script>
