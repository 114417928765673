<template>
  <div class="faq">
    <Heading noDecoration type="h4" class="mt-5 mb-9">
      How to use these codes?
    </Heading>
    <div class="faq__item">
      <div class="faq__item-num">01</div>
      <div>
        <div class="faq__item-title">
          Select one ticket type to start registration or share your code
        </div>
        <div class="faq__item-description">
          <b>Pay attention!</b>
          Sponsor ticket codes are only for {{ company }} representatives. Be
          careful sharing these codes.
        </div>
      </div>
    </div>
    <div class="faq__item">
      <div class="faq__item-num">02</div>
      <div>
        <div class="faq__item-title">Invite attendees</div>
        <div class="faq__item-description">
          Go ahead and invite participants using
          <img
            src="../../assets/img/icons/message-add.svg"
            alt="Invite attendees"
            class="faq__icon"
          />
          icon. You may share your code with an unlimited number of people,
          however it is first come, first served. They will receive an email
          with a code and link to register for a ticket.
        </div>
      </div>
    </div>
    <div class="faq__item">
      <div class="faq__item-num">03</div>
      <div>
        <div class="faq__item-title">Register participants</div>
        <div class="faq__item-description">
          You may register participants yourself by clicking
          <img
            src="../../assets/img/icons/user-add.svg"
            alt="Register attendees"
            class="faq__icon"
          />
          icon. You will only be able to register as many participants as
          available under ticket type category. You will need to enter contact
          details for each attendee, and they will receive an email confirmation
          containing a link to access their tickets.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  name: "SponsorCodesFAQ",
  props: {
    company: {
      type: String,
      default: "",
    },
  },
  components: { Heading },
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.faq {
  position: relative;

  &:after {
    content: "";
    background-color: $light-gray;
    position: absolute;
    bottom: 0;
    left: -16px;
    width: calc(100% + 16px * 2);
    height: 100%;
    z-index: -5;
  }

  &__icon {
    filter: brightness(3);
    height: 12px;
    margin: 0 2px 4px;
  }

  &__item {
    position: relative;
    padding-bottom: 40px;
    display: flex;

    &-num {
      font-family: $sarabun;
      font-weight: bold;
      font-size: 120px;
      line-height: 120px;
      letter-spacing: 0.05em;
      color: $white;
      margin-right: 53px;
      z-index: -1;
      position: absolute;
      top: -15%;
    }

    &-title {
      padding-bottom: 14px;
      font-weight: bold;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: $dark-gray;
    }

    &-description {
      line-height: 32px;
      letter-spacing: 0.05em;
    }
  }

  @media (min-width: $media-sm) {
    &:after {
      width: calc(100% + 24px * 2);
      left: -24px;
    }
  }
  @media (min-width: $media-md) {
    width: 50%;
    padding-left: 32px;
    &:after {
      width: calc(100% + 24px);
      height: 115%;
      left: 0;
    }
  }
  @media (min-width: $media-lg) {
    &:after {
      width: 180%;
      height: 110%;
      left: 0;
    }
  }
}
</style>
