<template>
  <div class="kit">
    <Preloader v-if="isKitLoading" :loading="isKitLoading" class="mt-15" />
    <div class="kit__tabs">
      <div
        @click.prevent="setActiveTab('general')"
        :class="{ active: isTabActive('general') }"
      >
        General info
      </div>
      <div
        @click.prevent="setActiveTab('participants')"
        :class="{ active: isTabActive('participants') }"
      >
        Participants
      </div>
      <div
        @click.prevent="setActiveTab('codes')"
        :class="{ active: isTabActive('codes') }"
      >
        Codes
      </div>
      <!--      <div
        @click.prevent="setActiveTab('drawings')"
        :class="{ active: isTabActive('drawings') }"
      >
        Drawings
      </div>-->
      <div
        @click.prevent="setActiveTab('accommodation')"
        :class="{ active: isTabActive('accommodation') }"
      >
        Accommodation
      </div>
      <div
        @click.prevent="setActiveTab('cfp')"
        :class="{ active: isTabActive('cfp') }"
      >
        CFP
      </div>
      <div
        @click.prevent="setActiveTab('shipping')"
        :class="{ active: isTabActive('shipping') }"
      >
        Shipping
      </div>
      <div
        @click.prevent="setActiveTab('profile')"
        :class="{ active: isTabActive('profile') }"
      >
        Update Profile
      </div>
    </div>
    <div v-if="kit" class="kit__content padding-x">
      <div v-show="isTabActive('general')">
        <General :kit="kit" />
      </div>
      <div v-show="isTabActive('codes')" class="kit__codes">
        <Error v-if="codesError">
          <template #heading>
            Sorry, something went wrong while loading codes
          </template>
          <template #body>
            Please try again or contact us at
            <a href="mailto:info@arctic-con.com" class="error__link">
              info@arctic-con.com
            </a>
          </template>
        </Error>
        <div v-else>
          <Heading noDecoration class="kit__codes-title"> Codes</Heading>
          <div class="kit__codes-block">
            <SponsorCodes :tickets="codes" :loading="isOrderLoading" />
            <SponsorCodesFAQ v-if="codes" :company="codes[0].organization" />
          </div>
        </div>
      </div>
      <div v-show="isTabActive('drawings')">
        <Heading noDecoration class="kit__codes-title">Drawings</Heading>
        <Preloader :loading="isDrawingsLoading" />
        <h3 v-show="!isDrawingsLoading && !drawings">
          Sorry, you don't seem to have any drawings yet
        </h3>
        <div v-for="draw in drawings" :key="draw.id">
          <Drawing :drawing="draw" @winner="getDrawings" />
        </div>
      </div>
      <div v-show="isTabActive('participants')" class="kit__participants">
        <Heading noDecoration text-align="center" class="kit__codes-title">
          Participants
        </Heading>
        <Preloader :loading="isRegistrationsLoading" />
        <h3 v-show="!isRegistrationsLoading && !registrations">
          Sorry, you can’t see registrations yet
        </h3>
        <Participants
          v-if="registrations && registrations.length"
          :data="registrations"
          :date="event.start"
          :eventName="event.name"
        />
      </div>
      <div v-show="isTabActive('accommodation')">
        <AccommodationInfo :accommodations="kit.accommodation" />
      </div>
      <div v-show="isTabActive('cfp')">
        <CFPInfo :eventName="event.name" />
      </div>
      <div v-show="isTabActive('shipping')">
        <Shipping :shipping="kit.exhibitor_instructions" />
      </div>
      <div v-show="isTabActive('profile')">
        <UpdateProfile
          :sponsor="sponsor"
          :isSponsorLoading="isSponsorLoading"
        />
      </div>
    </div>
    <Error v-if="kitError">
      <template #heading> Something went wrong </template>
      <template #body>
        Please try again or contact us at
        <a href="mailto:info@arctic-con.com" class="error__link">
          info@arctic-con.com
        </a>
      </template>
    </Error>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import Error from "@/components/common/Error";
import SponsorCodesFAQ from "./SponsorCodesFAQ";
import CFPInfo from "./CFPInfo";
import AccommodationInfo from "./AccommodationInfo";
import Shipping from "./Shipping";
import SponsorCodes from "./SponsorCodes";
import General from "./General";
import Drawing from "./Drawing";
import Participants from "./Participants";
import UpdateProfile from "./UpdateProfile";

export default {
  name: "ExhibitorKit",
  props: {},
  components: {
    Participants,
    UpdateProfile,
    Error,
    General,
    SponsorCodes,
    Drawing,
    Shipping,
    AccommodationInfo,
    CFPInfo,
    Preloader,
    Heading,
    SponsorCodesFAQ,
  },
  data: () => ({
    activeTab: "general",
    isKitLoading: false,
    isRegistrationsLoading: false,
    isOrderLoading: false,
    isDrawingsLoading: false,
    isSponsorLoading: false,
    kit: null,
    registrations: null,
    kitError: null,
    codes: null,
    codesError: null,
    drawings: null,
    sponsor: {},
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
    }),
    ...mapState(["currentEventID"]),
    adminUrl() {
      return process.env.VUE_APP_ADMIN_PANEL_URL;
    },
    eventId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if (history.state.forward) {
      this.activeTab = "general"; //for redirect with browser "back" arrow
    } else if (
      history.state.back &&
      (history.state.back.includes("invite") ||
        history.state.back.includes("register-multiple"))
    ) {
      this.activeTab = "codes";
    } else {
      this.activeTab = "general";
    }

    // for getting an event name
    if (!Object.keys(this.event).length) {
      this.isKitLoading = true;
      this.getEvent(this.eventId || this.currentEventID)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isKitLoading = false;
        });
    }

    this.isKitLoading = true;
    this.isOrderLoading = true;
    this.isRegistrationsLoading = true;
    this.getRegistrations();
    this.getKitInfo();
    this.getOrder();
    // this.getDrawings();
    this.getSponsor();
  },
  methods: {
    ...mapActions(["GET", "getEvent"]),
    getRegistrations() {
      return this.GET({
        route: `/ticketing/public/event/${
          this.eventId || this.currentEventID
        }/order/${this.$route.params.token}/registrations`,
      })
        .then((resp) => {
          // console.log("get Registrations", resp.data);
          this.registrations = resp.data;
          this.isRegistrationsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isRegistrationsLoading = false;
        });
    },
    getKitInfo() {
      return this.GET({
        route: `/public/event/${
          this.eventId || this.currentEventID
        }/exhibitor_kit`,
      })
        .then((resp) => {
          // console.log("get kit", resp.data);
          this.kit = resp.data;
          this.isKitLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.kitError = true;
          this.isKitLoading = false;
        });
    },
    getOrder() {
      return this.GET({
        route: `/ticketing/public/event/${
          this.eventId || this.currentEventID
        }/order/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.codes = resp.data;
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codesError = true;
          this.isOrderLoading = false;
        });
    },
    getSponsor() {
      this.isSponsorLoading = true;
      return this.GET({
        route: `/public/event/${
          this.eventId || this.currentEventID
        }/exhibitor_kit/${this.$route.params.token}`,
      })
        .then(({ data }) => {
          this.sponsor = data;
          this.isSponsorLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isSponsorLoading = false;
        });
    },
    getDrawings() {
      this.isDrawingsLoading = true;
      return this.GET({
        route: `/event/${this.eventId || this.currentEventID}/exhibitor_kit/${
          this.$route.params.token
        }/drawing`,
      })
        .then(({ data }) => {
          this.drawings = data;
          this.isDrawingsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isDrawingsLoading = false;
        });
    },
    isTabActive(menuItem) {
      return this.activeTab === menuItem;
    },
    setActiveTab(menuItem) {
      this.activeTab = menuItem;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.kit {
  padding-top: 70px;

  &__tabs {
    position: fixed;
    top: 72px;
    width: 100%;
    z-index: 5;
    background-color: $white;
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden;

    div {
      cursor: pointer;
      padding: 10px;
      font-size: 14px;
      color: $dark-gray;
      text-transform: uppercase;
    }
  }

  &__tabs .active {
    background-color: $light-gray;
  }

  &__content {
    padding-top: 135px;
    padding-bottom: 35px;
  }

  &__codes {
    &-title {
      margin-bottom: 28px;
    }

    &-block {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__panel {
    padding-top: 100px;
    padding-bottom: 120px;
    background-color: $light-gray;
  }

  @media (min-width: $media-xs) {
    &__content {
      padding-top: 85px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 122px;
    &__tabs {
      top: 109px;
    }
    &__codes {
      &-title {
        margin-bottom: 56px;
      }

      &-block {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @media (min-width: $media-md) {
    &__tabs {
      div {
        font-size: 16px;
        padding: 24px 35px;
      }
    }

    &__codes {
      &-block {
        flex-wrap: nowrap;
      }
    }
  }

  @media (min-width: $media-lg) {
    &__tabs {
      div {
        padding: 24px 50px;
      }
    }

    &__content {
      padding-top: 129px;
      padding-bottom: 136px;
    }

    &__participants {
      margin: 0 -10%;
    }

    &__codes {
      &-title {
        margin-bottom: 87px;
      }
    }
  }
}
</style>
