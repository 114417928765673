<template>
  <Heading noDecoration text-align="center" class="title">Schedule</Heading>
  <div
    v-for="(item, index) in kit.exhibitor_schedule"
    v-bind:key="index + item.start_time"
    class="timing"
  >
    <div class="timing__date padding-x">
      &nbsp; &nbsp; &nbsp;
      {{ getDate(item.start_time) }}
    </div>
    <div class="d-flex mt-14 mb-10">
      <div class="timing__time">
        {{ getTime(item.start_time) }}
        <hr />
        {{ getTime(item.end_time) }}
      </div>
      <dl
        v-for="[topic, list] in Object.entries(item.schedule_item)"
        :key="topic"
      >
        <dt class="timing__list-topic">{{ topic }}</dt>
        <dd
          v-for="(item, index) in list"
          :key="item + index"
          class="timing__list-item"
        >
          {{ item }}
        </dd>
      </dl>
    </div>
  </div>
  <div
    v-for="(facility, index) in kit.facility"
    v-bind:key="index"
    class="pt-8"
  >
    <Heading noDecoration type="h4" class="mb-6">
      {{ facility.title }}
    </Heading>
    <div class="facility">
      <img :src="facility.image" :alt="facility.title" class="facility__img" />
      <div v-html="facility.description" class="facility__descr" />
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  name: "General",
  props: {
    kit: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Heading },
  data: () => ({}),
  methods: {
    getDate(date) {
      const startDate = new Date(date);
      return startDate.toLocaleString("en-US", {
        timeZone: "US/Alaska",
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    },
    getTime(date) {
      const startDate = new Date(date);
      return startDate.toLocaleString("en-US", {
        timeZone: "US/Alaska",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.title {
  padding-bottom: 30px;
}

.timing {
  margin: 0 -24 px;

  &__date {
    padding-top: 24px;
    padding-bottom: 24px;
    margin: 0 -24px;
    background-color: $light-gray;
    color: $dark-gray;
    font-family: $sarabun;
    font-weight: $sarabunSemibold;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__time {
    min-width: 60px;
    text-align: center;
    margin-right: 27px;
    font-size: 16px;
    line-height: 16px;

    hr {
      border-top: 2px solid $dark-gray;
    }
  }

  &__list-topic {
    color: $dark-gray;
    font-weight: $sansBold;
  }

  &__list-item {
    display: list-item;
    list-style-type: disc;
  }
}

.facility {
  display: flex;
  flex-wrap: wrap;

  &__img {
    width: 100%;
    height: fit-content;
  }

  &__descr {
    margin-top: 15px;
    margin-left: 24px;
    position: relative;

    &:after {
      content: "";
      width: 2px;
      height: 40%;
      background: $dark-gray;
      position: absolute;
      top: -25%;
      left: -3%;
    }
  }
}

@media (min-width: $media-xs) {
  .timing {
    &__time {
      min-width: 100px;
    }
  }
}

@media (min-width: $media-sm) {
  .title {
    padding-bottom: 56px;
  }
  .timing {
    &__time {
      min-width: 135px;
    }
  }
  .facility {
    flex-wrap: nowrap;

    &__img {
      width: 65%;
    }

    &__descr {
      &:after {
        width: 40%;
        height: 2px;
        top: 0;
        left: -40%;
      }
    }
  }
}

@media (min-width: $media-lg) {
  .title {
    padding-bottom: 85px;
  }
  .timing {
    &__date {
      margin: 0 -22%;
    }

    &__time {
      min-width: 172px;
    }
  }
}

@media (min-width: $media-xl) {
  .timing {
    &__date {
      margin: 0 -33%;
    }
  }
}
</style>
