<template>
  <Heading noDecoration text-align="center">Shipping & Stuff</Heading>
  <div class="shipping-wrapper">
    <div class="shipping-details">
      <div v-for="[topic, list] in Object.entries(parsedShipping)" :key="topic">
        <Heading noDecoration type="h4">
          {{ topic }}
        </Heading>
        <ul style="max-width: 951px">
          <li
            v-for="(item, index) in list"
            :key="item.slice(0, 5) + index"
            class="timing__list-item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
    <div class="banner-container" v-if="shipping.shipping_address">
      <div class="banner">
        <div class="banner-title">Shipping Address</div>
        <div class="banner-description">
          {{ shipping.shipping_address && shipping.shipping_address[0] }}
        </div>
        <div class="actions">
          <Tooltip
            :text="copied ? 'Address copied to clipboard' : 'Click to copy'"
            class="mt-5"
            style="display: block"
          >
            <Button
              @click="copyCode()"
              style="padding: 0; height: 56px; font-weight: 400"
            >
              Copy Shipping Details
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Tooltip from "@/components/common/Tooltip";
import Button from "@/components/common/Button";

export default {
  name: "Shipping",
  props: {
    shipping: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Heading,
    Tooltip,
    Button,
  },
  data: () => ({
    copied: false,
  }),
  computed: {
    parsedShipping() {
      // eslint-disable-next-line no-unused-vars
      const { shipping_address, ...rest } = this.shipping;
      return rest;
    },
  },
  methods: {
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.shipping.shipping_address[0]);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.shipping-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 47px;

  .banner {
    padding: 18px 23px;
    background-color: $light-gray;
    margin-bottom: 17px;
    box-sizing: border-box;
    max-width: 619px;
    order: 1;
    min-width: 466px;

    &-title {
      font-family: $sarabun;
      font-size: 24px;
      font-weight: $sarabunSemibold;
      color: $dark-gray;
      text-transform: uppercase;
    }

    &-description {
      margin-top: 24px;
    }
  }

  @media (min-width: $media-sm) {
    flex-wrap: nowrap;
    .banner {
      order: 1;
      margin-bottom: 0;
      margin-left: 24px;
      min-width: 374px;
      height: fit-content;
    }
  }
  @media (min-width: $media-lg) {
    margin-top: 87px;

    .banner {
      padding: 53px 72px;
      min-width: 466px;
    }
  }
}
</style>
