<template>
  <Preloader :loading="isEntryLoading" />
  <div class="draw">
    <h2 class="mb-1">{{ drawing.title }}</h2>
    <div class="d-flex">
      <!-- for testing: src="https://picsum.photos/seed/picsum/500/500"-->
      <img
        v-if="drawing.prize_image && drawing.prize_image.length"
        :src="drawing.prize_image"
        :alt="drawing.title"
        width="150"
        height="150"
        class="mr-6"
        style="object-fit: contain"
      />
      <div>
        <div><b>Message: </b>{{ drawing.message }}</div>
        <div><b>Prize: </b>{{ drawing.prize }}</div>
        <div v-if="drawing.winning_message.length">
          <b>Winning message: </b>
          {{ drawing.winning_message }}
        </div>
      </div>
      <div v-if="drawing.winner.length" class="candidate-box draw__winner mr-5">
        <div class="candidate-box-header">
          Winner
          <img
            src="../../assets/img/icons/features/party.svg"
            alt="congratulations"
            class="ml-2"
          />
        </div>
        <img
          v-if="drawing.winner_info.image"
          :src="drawing.winner_info.image"
          :alt="drawing.winner_info.display_name"
          width="100"
          height="100"
          class="mx-6"
          style="object-fit: contain"
        />
        <div class="ml-2">
          <p>
            {{ drawing.winner_info.first_name }}
            {{ drawing.winner_info.last_name }}
          </p>
          <!--            <SocialMedia-->
          <!--              v-if="drawing.winner_info.social_media.length"-->
          <!--              :social="drawing.winner_info.social_media"-->
          <!--              class="mt-3"-->
          <!--            />-->
          <p>
            {{ drawing.winner_info.organization }}
            <i v-show="drawing.winner_info.job_title">
              - {{ drawing.winner_info.job_title }}
            </i>
          </p>

          {{ drawing.winner_info.email }}
        </div>
      </div>
    </div>

    <Error v-if="entryError" class="mt-3">
      <template #heading>
        Seems there was an issue with your winner.
        {{ entryError }}
      </template>
      <template #body>
        Please try again later or contact us at
        <a href="mailto:info@arctic-con.com" class="error__link">
          info@arctic-con.com
        </a>
      </template>
    </Error>
    <h3 v-show="noEntries" style="text-align: center">
      Sorry, no entries submitted for drawing
    </h3>
    <div
      v-if="!entryError && !drawing.winner.length && !noEntries"
      class="mt-4"
    >
      To generate random winner:
      <Preloader :loading="isWinnerGenerating" />
      <div class="draw__random mt-6 mb-6">
        <div v-if="winnerGeneratedCandidate" class="candidate-box">
          <div class="candidate-box-header">
            Winner
            <img
              src="../../assets/img/icons/features/party.svg"
              alt="congratulations"
              class="ml-2"
            />
          </div>
          <img
            v-if="winnerGeneratedCandidate.image"
            :src="winnerGeneratedCandidate.image"
            :alt="winnerGeneratedCandidate.display_name"
            width="100"
            height="100"
            class="mx-6"
            style="object-fit: contain"
          />
          <div class="ml-2">
            <p>
              {{ winnerGeneratedCandidate.first_name }}
              {{ winnerGeneratedCandidate.last_name }}
            </p>
            <!--            <SocialMedia-->
            <!--              v-if="winnerGeneratedCandidate.social_media.length"-->
            <!--              :social="winnerGeneratedCandidate.social_media"-->
            <!--              class="mt-3"-->
            <!--            />-->
            <p>
              {{ winnerGeneratedCandidate.organization }}
              <i v-show="winnerGeneratedCandidate.job_title">
                - {{ winnerGeneratedCandidate.job_title }}
              </i>
            </p>

            {{ winnerGeneratedCandidate.email }}
          </div>
        </div>
        <Button white class="px-6 draw__btn" @click="drawWinner">
          Draw {{ winnerGeneratedCandidate ? " again " : "winner" }}
        </Button>
        <Button
          v-show="winnerGeneratedCandidate"
          class="px-12 draw__btn"
          @click="checkWinner(winnerGeneratedCandidate)"
        >
          Set winner
        </Button>
      </div>
      <!--      Or select it manually from the list:
      <div class="d-flex mt-6">
        <div
          v-for="entry in entries"
          :key="entry.id"
          class="draw__entry"
          :class="{
            'draw__entry-selected': entry.id === winnerCandidate?.id,
          }"
          @click="chooseEntry(entry)"
        >
          {{ entry.user }}
        </div>
        <Button
          v-show="winnerCandidate"
          class="px-12 draw__btn"
          :disabled="isWinnerLoading"
          @click="checkWinner(winnerCandidate)"
        >
          Set winner
        </Button>
      </div>-->
    </div>
    <!--    {{ drawing.drawing_time }}-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Preloader from "@/components/common/Preloader";
// import SocialMedia from "@/components/common/SocialMedia";
import Button from "@/components/common/Button";
import Error from "@/components/common/Error";

export default {
  name: "Drawing",
  props: {
    drawing: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["winner"],
  components: {
    Preloader,
    Button,
    Error,
    // SocialMedia
  },
  data: () => ({
    isEntryLoading: false,
    isWinnerGenerating: false,
    isWinnerLoading: false,
    noEntries: false,
    entries: null,
    // winnerCandidate: null,
    winnerGeneratedCandidate: null,
    entryId: null,
    entryError: null,
  }),
  created() {
    this.isEntryLoading = true;
    this.getEntry();
  },
  computed: {
    ...mapState(["currentEventID"]),
  },
  methods: {
    ...mapActions(["GET", "POST"]),
    getEntry() {
      return this.GET({
        route: `/event/${this.currentEventID}/exhibitor_kit/${this.$route.params.token}/drawing/${this.drawing.id}/entry`,
      })
        .then(({ data }) => {
          if (data) {
            this.entries = data;
          } else {
            this.noEntries = true;
          }
          this.isEntryLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEntryLoading = false;
        });
    },
    // chooseEntry(entry) {
    //   this.winnerCandidate = entry;
    // },
    drawWinner() {
      // hash id 61e7e403050510414e2d9c35ff568540de98e9adb98dc6be7c945aa6656a57aed5624d99933d2ef1bb6be9bcce6db8342316f7b324b0f64d4d588a66f73d17e1
      this.isWinnerGenerating = true;
      return this.POST({
        route: `/event/${this.currentEventID}/exhibitor_kit/${this.$route.params.token}/drawing/${this.drawing.id}/draw_winner`,
        data: {},
      })
        .then(({ data }) => {
          // console.log("draw_winner", data);
          this.winnerGeneratedCandidate = data.user;
          this.entryId = data.entry_id;
          this.isWinnerGenerating = false;
        })
        .catch((err) => {
          console.log(err);
          this.isWinnerGenerating = false;
        });
    },
    checkWinner(info) {
      this.isWinnerLoading = true;
      const userId = info.id ? info.id : info.user.id;
      return this.GET({
        route: `/ticketing/public/event/${this.currentEventID}/order/${this.$route.params.token}/attendee/${userId}/ticket`,
        data: {},
      })
        .then(({ data }) => {
          // console.log("checkWinner", data);
          this.setWinner(this.entryId, data || info);
        })
        .catch((err) => {
          console.log(err);
          this.entryError = err.response.data.msg || "";
          this.isWinnerLoading = false;
        });
    },
    setWinner(id, data) {
      console.log("obj", { entry_id: id, winner_info: data });
      return this.POST({
        route: `/event/${this.currentEventID}/exhibitor_kit/${this.$route.params.token}/drawing/${this.drawing.id}/set_winner`,
        data: { entry_id: id, winner_info: data },
      })
        .then(({ data }) => {
          this.entryId = null;
          this.entryError = null;
          // this.winnerCandidate = null;
          this.winnerGeneratedCandidate = null;
          this.isWinnerLoading = false;
          this.$emit("winner");
          console.log("winner Chosen", data);
        })
        .catch((err) => {
          console.log(err);
          this.entryError = err.response.data.msg || "";
          this.isWinnerLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.draw {
  background-color: $light-gray;
  padding: 20px;
  margin: 20px 0;

  &__random {
    display: flex;
    flex-wrap: wrap;
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
  }

  &__winner {
    text-align: center;
    margin-left: auto;
  }

  &__entry {
    cursor: pointer;
    background-color: $white;
    border: 1px solid $dark-gray;
    padding: 15px;
    margin-right: 24px;

    &-selected {
      border: 1px solid $blue;
      color: $blue;
    }
  }

  @media (min-width: $media-md) {
    &__random {
      flex-wrap: nowrap;
    }
    &__btn {
      width: auto;
      margin-top: 0;

      &:first-of-type {
        margin-right: 24px;
      }
    }
  }
  @media (min-width: $media-lg) {
  }
}

.candidate-box {
  border: 1px solid $blue;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 20px;
  padding-bottom: 5px;

  &-header {
    background-color: $blue;
    color: $dark-gray;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }
}
</style>
